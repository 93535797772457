import Contact from "../components/Contact";
import ProjectsComp1 from "../components/Projects";
import "./../App.css";

function ProjectsPage() {
  return (
    <>
      <ProjectsComp1 />
      <Contact />
    </>
  );
}

export default ProjectsPage;
